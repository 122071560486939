import HeaderSection from "../components/HeaderSection"
import BackLink from "../components/BackLink"
import { Link } from 'react-router-dom'

export default function CaracPage() {

    return <>
        <main className="l-main">
            <HeaderSection title="Wasoria DEMO">
            </HeaderSection>


            <div className="c-select-carac">
                <h2 className="c-select-carac__title || u-title">Selectionner un flux à analyser :</h2>
                <div className="c-select-carac__content">
                  <Link className="c-select-carac__item" to="/carac/1">
                      <h3 className="c-select-caract__subtitle || u-subtitle">Portique table PETClair</h3>
                      <div className="c-select-carac__image">
                          <img className="c-select-carac__thumbnail" src="/assets/images/1.png" alt="Carac 1" width={400} height={300} />
                      </div>
                  </Link>
                  <Link className="c-select-carac__item" to="/carac/2">
                      <h3 className="c-select-caract__subtitle || u-subtitle">Portique table Flux développement</h3>
                      <div className="c-select-carac__image">
                          <img className="c-select-carac__thumbnail" src="/assets/images/2.png" alt="Carac 2" width={400} height={300} />
                      </div>
                  </Link>
                </div>
            </div>
        </main>
    </>
} 