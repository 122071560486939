
export function AlertIcon({count}) {
    return <>
    <div className="c-alert-icon">
        <svg className="u-icon c-alert-icon__icon" xmlns="http://www.w3.org/2000/svg" width="41.525" height="41.539" viewBox="0 0 41.525 41.539">
            <path className="c-alert-icon__path" id="light-emergency-on_1_" data-name="light-emergency-on (1)" d="M41.525,41.531H0V39.8A5.2,5.2,0,0,1,5.191,34.61H36.335A5.2,5.2,0,0,1,41.525,39.8ZM6.433,9.006,2.422,5.165.031,7.664,4.04,11.5l2.393-2.5Zm6.682-4.313-2.1-4.7L7.857,1.4l2.1,4.7,3.159-1.408ZM41.494,7.664,39.1,5.167,35.092,9.008l2.393,2.5,4.009-3.841ZM33.668,1.4,30.509-.008l-2.1,4.7L31.57,6.1l2.1-4.7Zm2.666,21.1a15.572,15.572,0,0,0-31.144,0V31.15H36.335Zm-15.572-3.46A3.464,3.464,0,0,0,17.3,22.5h-3.46a6.928,6.928,0,0,1,6.921-6.921Z" transform="translate(0 0.008)" fill="rgba(11,66,93,0.97)"/>
        </svg>
        { count > 0 &&
        <div className="c-alert-icon__wrapper">
            <div className="c-alert-icon__counter">
                <span className="c-alert-icon__count">{count}</span>
            </div>
        </div>
        }
    </div>
    </>
}
export function ParameterIcon({color, rotate} ) {
    return <>
        <svg className={"u-icon c-parameter-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="37.375" height="41.539" viewBox="0 0 37.375 41.539">
          <path className={"c-parameter-icon__path  || " + (color ? '--is-' + color : '')} id="Tracé_128" data-name="Tracé 128" d="M26.36,31.154a5.192,5.192,0,0,0,7.091,1.907l.005,0,.77-.445a15.537,15.537,0,0,0,4.929,2.845v.888a5.192,5.192,0,0,0,10.385,0v-.888A15.536,15.536,0,0,0,54.47,32.61l.774.447a5.2,5.2,0,1,0,5.192-9h0l-.768-.443a15.752,15.752,0,0,0,0-5.694l.768-.443a5.2,5.2,0,1,0-5.192-9l-.77.445A15.538,15.538,0,0,0,49.54,6.08V5.192a5.192,5.192,0,0,0-10.385,0V6.08a15.536,15.536,0,0,0-4.929,2.849l-.774-.448a5.2,5.2,0,1,0-5.192,9h0l.768.443a15.751,15.751,0,0,0,0,5.694l-.768.443A5.2,5.2,0,0,0,26.36,31.154ZM44.348,13.846a6.923,6.923,0,1,1-6.923,6.923A6.923,6.923,0,0,1,44.348,13.846Z" transform="translate(-25.66)" fill="rgba(11,66,93,0.97)"/>
        </svg>
    </>
}
export function HandCheckIcon({color, rotate} ) {
    return <>
        <svg className={"u-icon c-handcheck-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="41.599" height="36.397" viewBox="0 0 41.599 36.397">
          <path className={"c-handcheck-icon__path  || " + (color ? '--is-' + color : '')} id="handshake" d="M20.9,17.964,34.832,29.609l-9.87,7.4a6.932,6.932,0,0,1-8.32,0L6.011,29.039A5.2,5.2,0,0,0,2.891,28H1.735A1.734,1.734,0,0,1,0,26.265V7.089A1.72,1.72,0,0,1,1.532,5.377,16.862,16.862,0,0,0,8.169,2.908,8.767,8.767,0,0,1,17.9,4.247l1.069,1.028-6.946,6.779a5.339,5.339,0,0,0-.69,6.826,5.385,5.385,0,0,0,4.236,2.182,5.159,5.159,0,0,0,3.647-1.5l1.676-1.6ZM34.587,2.908a8.78,8.78,0,0,0-9.2.95L14.461,14.522a1.872,1.872,0,0,0-.314,2.335,1.736,1.736,0,0,0,1.281.737,1.717,1.717,0,0,0,1.366-.5l6.271-5.944a1.734,1.734,0,0,1,2.4,2.5l-2.047,1.9L38.311,28h1.555A1.734,1.734,0,0,0,41.6,26.267V7.03a1.74,1.74,0,0,0-1.458-1.7A15.71,15.71,0,0,1,34.586,2.91Z" transform="translate(0 -2.002)" fill="rgba(11,66,93,0.97)"/>
        </svg>
    </>
}
export function BoardIcon({color, rotate} ) {
    return <>
        <svg className={"u-icon c-board-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="24.722" height="24.723" viewBox="0 0 24.722 24.723">
          <g className="c-board-icon__group" id="Groupe_328" data-name="Groupe 328" transform="translate(-191.447 -37.639)">
            <path className={"c-board-icon__path  || " + (color ? '--is-' + color : '')} id="Tracé_124" data-name="Tracé 124" d="M4.12,0h3.09a4.12,4.12,0,0,1,4.12,4.12v3.09a4.12,4.12,0,0,1-4.12,4.12H4.12A4.12,4.12,0,0,1,0,7.211V4.12A4.12,4.12,0,0,1,4.12,0Z" transform="translate(191.447 37.639)" fill="rgba(11,66,93,0.97)"/>
            <path className={"c-board-icon__path  || " + (color ? '--is-' + color : '')} id="Tracé_125" data-name="Tracé 125" d="M281.454,0h3.09a4.12,4.12,0,0,1,4.12,4.12v3.09a4.12,4.12,0,0,1-4.12,4.12h-3.09a4.12,4.12,0,0,1-4.12-4.12V4.12A4.12,4.12,0,0,1,281.454,0Z" transform="translate(-72.496 37.639)" fill="rgba(11,66,93,0.97)"/>
            <path className={"c-board-icon__path  || " + (color ? '--is-' + color : '')} id="Tracé_126" data-name="Tracé 126" d="M4.12,277.333h3.09a4.12,4.12,0,0,1,4.12,4.12v3.09a4.12,4.12,0,0,1-4.12,4.12H4.12A4.12,4.12,0,0,1,0,284.544v-3.09A4.12,4.12,0,0,1,4.12,277.333Z" transform="translate(191.447 -226.303)" fill="rgba(11,66,93,0.97)"/>
            <path className={"c-board-icon__path  || " + (color ? '--is-' + color : '')} id="Tracé_127" data-name="Tracé 127" d="M281.454,277.333h3.09a4.12,4.12,0,0,1,4.12,4.12v3.09a4.12,4.12,0,0,1-4.12,4.12h-3.09a4.12,4.12,0,0,1-4.12-4.12v-3.09A4.12,4.12,0,0,1,281.454,277.333Z" transform="translate(-72.496 -226.303)" fill="rgba(11,66,93,0.97)"/>
          </g>
        </svg>
    </>
}

export function HelpIcon({color, rotate} ) {
    return <>
    <svg className={"u-icon c-help-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="36.398" height="36.398" viewBox="0 0 36.398 36.398">
        <path className={"c-help-icon__path || " + (color ? '--is-' + color : '')} id="interrogation" d="M18.2,0A18.2,18.2,0,1,0,36.4,18.2,18.2,18.2,0,0,0,18.2,0Zm0,30.331a1.517,1.517,0,1,1,1.517-1.517A1.517,1.517,0,0,1,18.2,30.331Zm2.927-11.365a3.006,3.006,0,0,0-1.41,2.656v1.127a1.517,1.517,0,0,1-3.033,0V21.622a6,6,0,0,1,2.979-5.308,3.033,3.033,0,0,0,1.517-3.223,3.07,3.07,0,0,0-2.427-2.419,3.033,3.033,0,0,0-3.585,2.977,1.517,1.517,0,0,1-3.033,0,6.066,6.066,0,1,1,8.993,5.316Z" fill="rgba(11,66,93,0.97)"/>
    </svg>
    </>
}
export function ListIcon({color, rotate} ) {
    return <>
        <svg className={"u-icon c-list-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="24.5" height="24.5" viewBox="0 0 24.5 24.5">
          <path className={"c-list-icon__path  || " + (color ? '--is-' + color : '')} id="rectangle-list" d="M19.4,1H5.1A5.357,5.357,0,0,0,0,6.568V19.932A5.357,5.357,0,0,0,5.1,25.5H19.4a5.357,5.357,0,0,0,5.1-5.568V6.568A5.357,5.357,0,0,0,19.4,1ZM6.635,20.489a1.677,1.677,0,0,1,0-3.341,1.677,1.677,0,0,1,0,3.341Zm0-5.568a1.677,1.677,0,0,1,0-3.341,1.677,1.677,0,0,1,0,3.341Zm0-5.568A1.606,1.606,0,0,1,5.1,7.682a1.606,1.606,0,0,1,1.531-1.67,1.606,1.606,0,0,1,1.531,1.67A1.606,1.606,0,0,1,6.635,9.352Zm11.74,10.58H11.229a1.118,1.118,0,0,1,0-2.227h7.146a1.118,1.118,0,0,1,0,2.227Zm0-5.568H11.229a1.118,1.118,0,0,1,0-2.227h7.146a1.118,1.118,0,0,1,0,2.227Zm0-5.568H11.229a1.071,1.071,0,0,1-1.021-1.114,1.071,1.071,0,0,1,1.021-1.114h7.146A1.071,1.071,0,0,1,19.4,7.682,1.071,1.071,0,0,1,18.375,8.8Z" transform="translate(0 -1)" fill="rgba(11,66,93,0.97)"/>
        </svg>
    </>
}
export function FileIcon({color, rotate} ) {
    return <>
        <svg className={"u-icon c-file-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="30.332" height="36.398" viewBox="0 0 30.332 36.398">
          <path className={"c-file-icon__path || " + (color ? '--is-' + color : '')} id="file-pdf" d="M11.069,24.432a1.344,1.344,0,0,1-1.35,1.3H8.461V23.128H9.735a1.344,1.344,0,0,1,1.35,1.3Zm10.646-12.3h9.918A10.672,10.672,0,0,0,29.223,8.4L23.945,3.109A10.672,10.672,0,0,0,20.214.7v9.918a1.521,1.521,0,0,0,1.517,1.517Zm-4.413,11H16.028v5.308H17.3a1.344,1.344,0,0,0,1.35-1.3v-2.7A1.344,1.344,0,0,0,17.3,23.128Zm15.029-7.219V28.815A7.586,7.586,0,0,1,24.749,36.4H9.583A7.586,7.586,0,0,1,2,28.815V7.583A7.586,7.586,0,0,1,9.583,0h6.84c.243,0,.485.015.743.03V10.616a4.563,4.563,0,0,0,4.55,4.55H32.3c.015.243.03.485.03.743ZM12.965,24.432a3.23,3.23,0,0,0-3.245-3.2H8.066A1.521,1.521,0,0,0,6.55,22.749v6.734a.935.935,0,0,0,.94.94.945.945,0,0,0,.94-.94v-1.85H9.7a3.23,3.23,0,0,0,3.245-3.2Zm7.583,0a3.23,3.23,0,0,0-3.245-3.2H15.649a1.521,1.521,0,0,0-1.517,1.517v6.734a.862.862,0,0,0,.94.849h2.214a3.23,3.23,0,0,0,3.245-3.2v-2.7Zm7.264-2.245a.935.935,0,0,0-.94-.94h-3.5a.935.935,0,0,0-.94.94v7.295a.935.935,0,0,0,.94.94.945.945,0,0,0,.94-.94v-2.73h1.881a.935.935,0,0,0,.94-.94.945.945,0,0,0-.94-.94H24.309V23.143h2.563a.935.935,0,0,0,.94-.94Z" transform="translate(-2)" fill="rgba(11,66,93,0.97)"/>
        </svg>
    </>
}
export function MarkerIcon({color, rotate} ) {
    return <>
        <svg className={"u-icon c-marker-icon || " + (rotate ? ' --' + rotate : '')} id="marker" xmlns="http://www.w3.org/2000/svg" width="30.472" height="36.397" viewBox="0 0 30.472 36.397">
      <path className={"c-marker-icon__path || " + (color ? '--is-' + color : '')} id="Tracé_90" data-name="Tracé 90" d="M17.236,0A15.253,15.253,0,0,0,2,15.236c0,8.048,12.8,19.1,14.252,20.329l.984.832.984-.832c1.454-1.234,14.252-12.282,14.252-20.329A15.253,15.253,0,0,0,17.236,0Zm0,22.854a7.618,7.618,0,1,1,7.618-7.618,7.618,7.618,0,0,1-7.618,7.618Z" transform="translate(-2)" fill="rgba(11,66,93,0.97)"/>
      <circle id="Ellipse_38" data-name="Ellipse 38" cx="4.178" cy="4.178" r="4.178" transform="translate(11.14 11.14)" fill="rgba(11,66,93,0.97)"/>
    </svg>
    </>
}


export function HexagonExclamationIcon({color, rotate} ) {
    return <>
        <svg className={"u-icon c-hexagon-exclamation-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="41.526" height="37.952" viewBox="0 0 41.526 37.952">
            <path className={"c-hexagon-exclamation-icon__path || " + (color ? '--is-' + color : '')} id="hexagon-exclamation" d="M40.3,15.629,34.263,5.278A8.654,8.654,0,0,0,26.812,1h-12.2A8.657,8.657,0,0,0,7.161,5.278L1.124,15.629a8.636,8.636,0,0,0,0,8.693L7.162,34.674a8.654,8.654,0,0,0,7.451,4.278h12.2a8.657,8.657,0,0,0,7.451-4.278L40.3,24.323a8.636,8.636,0,0,0,0-8.693ZM19.013,11.351a1.725,1.725,0,0,1,3.45,0V20.84a1.725,1.725,0,0,1-3.45,0Zm1.725,18.977a2.588,2.588,0,1,1,2.588-2.588A2.589,2.589,0,0,1,20.738,30.328Z" transform="translate(0.049 -1)" fill="#65e476"/>
        </svg>
    </>
}

export function ShieldCheckIcon({color, rotate} ) {
    return <>
    <svg className={"u-icon c-shield-check-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="36.534" height="43.841" viewBox="0 0 36.534 43.841">
        <path className={"c-shield-check-icon__path || " + (color ? '--is-' + color : '')} id="shield-check" d="M32.288,3.909,20.844.093a1.827,1.827,0,0,0-1.154,0L8.245,3.909A9.121,9.121,0,0,0,2,12.573V21.92C2,35.736,18.805,43.366,19.525,43.683a1.827,1.827,0,0,0,1.483,0c.72-.318,17.525-7.948,17.525-21.763V12.573a9.121,9.121,0,0,0-6.245-8.664Zm-3.4,13.841-7.8,7.8a3.421,3.421,0,0,1-2.439,1.01h-.06a3.42,3.42,0,0,1-2.457-1.1l-4.212-4.384a1.827,1.827,0,1,1,2.632-2.524l4.1,4.274,7.65-7.672a1.827,1.827,0,1,1,2.583,2.583Z" transform="translate(-2 0)" fill="#65e476"/>
    </svg>
    </>
}
export function SensorOnIcon({color, rotate} ) {
        return <>
<svg className={"u-icon c-sensor-on-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="40.896" height="40.896" viewBox="0 0 40.896 40.896">
    <path className={"c-sensor-on-icon__path || " + (color ? '--is-' + color : '')} id="sensor-on" d="M39.192,20.448a1.7,1.7,0,0,1-1.7-1.7A15.354,15.354,0,0,0,22.152,3.408a1.7,1.7,0,0,1,0-3.408A18.766,18.766,0,0,1,40.9,18.744,1.7,1.7,0,0,1,39.192,20.448Zm-5.112-1.7A11.942,11.942,0,0,0,22.152,6.816a1.7,1.7,0,0,0,0,3.408,8.53,8.53,0,0,1,8.52,8.52,1.7,1.7,0,1,0,3.408,0Zm-6.816,3.408V32.376a8.53,8.53,0,0,1-8.52,8.52H8.52A8.53,8.53,0,0,1,0,32.376V22.152a8.53,8.53,0,0,1,8.52-8.52H18.744A8.53,8.53,0,0,1,27.264,22.152ZM8.52,20.448a1.7,1.7,0,1,0-1.7,1.7A1.7,1.7,0,0,0,8.52,20.448Z" fill="#ffc400"/>
</svg>
</>
}
export function BanIcon({color, rotate} ) {
    return <>
        <svg className={"u-icon c-ban-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="40.739" height="40.739" viewBox="0 0 40.739 40.739">
            <path className={"c-ban-icon__path || " + (color ? '--is-' + color : '')} id="Tracé_89" data-name="Tracé 89" d="M20.37,0a20.37,20.37,0,1,0,20.37,20.37A20.37,20.37,0,0,0,20.37,0Zm0,5.092a15.187,15.187,0,0,1,8.839,2.838L7.931,29.207A15.253,15.253,0,0,1,20.37,5.092Zm0,30.554a15.179,15.179,0,0,1-8.837-2.838L32.809,11.531A15.253,15.253,0,0,1,20.37,35.647Z" fill="#65e476"/>
        </svg>
    </>
}
export function BoxesIcon({color, rotate} ) {
    return <>
        <svg className={"u-icon c-boxes-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="42.485" height="42.485" viewBox="0 0 42.485 42.485">
            <path className={"c-boxes-icon__path  || " + (color ? '--is-' + color : '')} id="Tracé_91" data-name="Tracé 91" d="M19.472,14.328A1.332,1.332,0,0,0,18.145,13H11.506v5.311a1.77,1.77,0,1,1-3.54,0V13H1.328A1.33,1.33,0,0,0,0,14.328V31.145a1.33,1.33,0,0,0,1.328,1.328H18.145a1.332,1.332,0,0,0,1.328-1.328Z" transform="translate(0 10.013)" fill="#ef675d"/>
            <path className={"c-boxes-icon__path  || " + (color ? '--is-' + color : '')} id="Tracé_92" data-name="Tracé 92" d="M32.472,14.328A1.332,1.332,0,0,0,31.145,13H24.506v5.311a1.77,1.77,0,1,1-3.54,0V13H14.328A1.33,1.33,0,0,0,13,14.328V31.145a1.33,1.33,0,0,0,1.328,1.328H31.145a1.332,1.332,0,0,0,1.328-1.328Z" transform="translate(10.013 10.013)" fill="#ef675d"/>
            <path className={"c-boxes-icon__path  || " + (color ? '--is-' + color : '')} id="Tracé_93" data-name="Tracé 93" d="M25.972,1.328A1.333,1.333,0,0,0,24.645,0H18.006V5.311a1.77,1.77,0,1,1-3.54,0V0H7.828A1.332,1.332,0,0,0,6.5,1.328V18.145a1.33,1.33,0,0,0,1.328,1.328H24.645a1.332,1.332,0,0,0,1.328-1.328Z" transform="translate(5.006)" fill="#ef675d"/>
        </svg>
    </>
}
export function CrossHexagonIcon({color, rotate} ) {
        return <>
<svg className={"u-icon c-cross-hexagon-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="46.926" height="42.892" viewBox="0 0 46.926 42.892">
    <path className={"c-cross-hexagon-icon__path  || " + (color ? '--is-' + color : '')} id="times-hexagon" d="M45.555,17.534l-6.826-11.7A9.78,9.78,0,0,0,30.31,1H16.523A9.788,9.788,0,0,0,8.1,5.835l-6.826,11.7a9.77,9.77,0,0,0,0,9.825L8.1,39.057a9.78,9.78,0,0,0,8.419,4.835H30.308a9.788,9.788,0,0,0,8.421-4.835l6.824-11.7a9.77,9.77,0,0,0,0-9.825ZM30.671,26.916a1.949,1.949,0,1,1-2.757,2.757L23.443,25.2l-4.471,4.471a1.949,1.949,0,0,1-2.757-2.757l4.471-4.471-4.471-4.471a1.949,1.949,0,0,1,2.757-2.757l4.471,4.471,4.471-4.471a1.949,1.949,0,1,1,2.757,2.757L26.2,22.445l4.471,4.471Z" transform="translate(0.048 -1)" fill="#ef675d"/>
</svg>
        </>
}
export function ClockIcon({color, rotate} ) {
        return <>
<svg className={"u-icon c-clock-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="42.504" height="42.504" viewBox="0 0 42.504 42.504">
    <path className={"c-clock-icon__path || " + (color ? '--is-' + color : '')} id="clock" d="M21.252,0A21.252,21.252,0,1,0,42.5,21.252,21.252,21.252,0,0,0,21.252,0Zm1.771,21.038a1.771,1.771,0,0,1-.831,1.5l-6.8,4.25a1.775,1.775,0,1,1-1.881-3.011l5.97-3.723V12.4a1.771,1.771,0,1,1,3.542,0Z" fill="#ffc400"/>
</svg>
</>
}
export function ArrowIcon({color, rotate} ) {
    return <>
        <svg className={"u-icon c-arrow-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="13.422" height="7.674" viewBox="0 0 13.422 7.674">
            <path className={"c-arrow-icon__path || " + (color ? ' --is-' + color : '')} id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M12.9,16.607l5.075-5.079a.955.955,0,0,1,1.355,0,.967.967,0,0,1,0,1.359l-5.751,5.755a.957.957,0,0,1-1.323.028L6.467,12.891a.959.959,0,0,1,1.355-1.359Z" transform="translate(-6.188 -11.246)" fill="rgba(11,66,93,0.97)"/>
        </svg>
    </>
}

export function StarIcon({color, rotate} ) {
    return <>
        <svg className={"u-icon c-star-icon || " + (rotate ? ' --' + rotate : '')} id="star icon" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
        	 viewBox="0 0 53.867 53.867" xmlSpace="preserve">
            <polygon className={"c-star-icon__path || " + (color ? '--is-' + color : '')} points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 
        	10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 "/>
        </svg>
    </>
}

export function CrossIcon({color, rotate} ) {
    return <>
        <svg className={"u-icon c-cross-icon || " + (rotate ? ' --' + rotate : '')} xmlns="http://www.w3.org/2000/svg" width="28.151" height="28.177" viewBox="0 0 28.151 28.177">
            <path className={"c-cross-icon__path || " + (color ? '--is-' + color : '')} id="cross-small" d="M24.256,19.584l8.439-8.448a3.263,3.263,0,0,0-4.606-4.606L19.641,14.97,11.179,6.5a3.264,3.264,0,0,0-4.616,4.616l8.465,8.463L6.588,28.029A3.263,3.263,0,1,0,11.2,32.637L19.641,24.2l8.437,8.439a3.264,3.264,0,1,0,4.616-4.616Z" transform="translate(-5.5 -5.549)" fill="#003c59"/>
        </svg>
    </>
}




// add u-icon  || " + (rotate ? ' --' + rotate : '') class on svg, add c- class on svg and path and add || + (color ? '--is-' + color : '') on class path