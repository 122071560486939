import  { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

export default function CaracGraphics(props) {

    const containerRef = useRef(null)
    const flowChartRef = useRef(null)
    const timestampChartRef = useRef(null)

    const flowRef = useRef(null)
    const timestampRef = useRef(null)

    const colorValues = {
        "PET-BOTTLE_CLEAR" : 'rgba(255, 206, 86, 1.0)',
        "PET-BOTTLE_COLOURED" : 'rgba(209, 199, 47, 1.0)',
        "PET-MONOTRAY" : 'rgba(60, 218, 1, 1.0)',
        "PET-MULTITRAY" : 'rgba(143, 188, 11, 1.0)',
        "PP" : 'rgba(44, 197, 136, 1.0)',
        "PS" : 'rgba(22, 127, 48, 1.0)',
        "PSE" : 'rgba(19, 181, 183, 1.0)',
        "HDPE" : 'rgba(255, 165, 154, 1.0)',
        "FILM" : 'rgba(255, 73, 54, 1.0)',
        "OTHER" : 'rgba(75, 192, 192, 1.0)',
    }


    // resize canvas to fit container when window is resized and when navigator is in full screen
    useEffect(() => {

        const resizeCanvas = () => {
            const containerHeight = containerRef.current.clientHeight
            const containerWidth = containerRef.current.clientWidth

            flowChartRef.current.height = (containerHeight / 2.3 - 35)
            flowChartRef.current.width = (containerWidth)

            timestampChartRef.current.height = (containerHeight / 1.7 - 30)
            timestampChartRef.current.width = (containerWidth)
        }

        window.addEventListener('resize', resizeCanvas)


        return () => {
            window.removeEventListener('resize', resizeCanvas)
        }
    }, [])

    useEffect(() => {

        const legendMagrinTop = {
            id: 'legendMagrinTop',
            beforeInit: function(chart, legend, options) {
                const fitValue = chart.legend.fit

                chart.legend.fit = function fit() {
                    fitValue.bind(chart.legend)()
                    return this.height += 35
                }
            }
        };

        const containerHeight = containerRef.current.clientHeight
        const containerWidth = containerRef.current.clientWidth

        flowChartRef.current.height = (containerHeight / 2.3 - 35)
        flowChartRef.current.width = (containerWidth)

        timestampChartRef.current.height = (containerHeight / 1.7 - 30)
        timestampChartRef.current.width = (containerWidth)


        const ctx1 = flowChartRef.current;
        const chart1 = new Chart(ctx1, {
            type: 'bar',
            data: {
                labels: [], // Labels for x-axis
                datasets: [{
                    label: 'Répartition par classe',
                    data: [], // Initial data for y-axis
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: function(value) {
                                return (value) + ' %';
                            }
                        }
                    },
                    x: {
                        ticks: {
                            display: false
                        }
                    }
                },
                animation: {
                    duration: 0
                },
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Répartition des classes'
                }
            }
        }});

        const ctx2 = timestampChartRef.current;
        // create a stacked bar chart
        const chart2 = new Chart(ctx2, {
            type: 'line',
            data: {
                labels: [], // Labels for x-axis
                datasets: [{
                    label: 'Répartition dans le temps',
                    data: [], // Initial data for y-axis
                    backgroundColor: 'rgba(75, 192, 75, 0.2)',
                    borderColor: 'rgba(75, 192, 75, 1)',
                    borderWidth: 1
                }]
            },
            plugins: [legendMagrinTop],
            options: {
                scales: {
                    x: {
                        // stacked: true
                    },
                    y: {
                        beginAtZero: true,
                        // stacked: true
                    }
                },
                animation: {
                    duration: 0
                },
            }
        });
        flowRef.current = chart1
        timestampRef.current = chart2

        return () => {
            chart1.destroy()
            chart2.destroy()
        }

    }, [])

    useEffect(() => {
        const objects = [...props.objects]


        // chart 1 - flow chart
        const flowDatas = {}
        objects.forEach(object => {
            if (flowDatas[object.class_name]) {
                flowDatas[object.class_name] += 1
            } else {
                flowDatas[object.class_name] = 1
            }
        })        

        const flowObjects = flowDatas
        Object.keys(flowObjects).forEach(key => {
            // round to 2 decimals
            flowObjects[key] = Math.round(flowObjects[key] / objects.length * 10000) / 100
        })

        // sort by value
        const sortable = Object.entries(flowObjects).sort(([,a],[,b]) => b-a)
        const sorted = Object.fromEntries(sortable)

        // select color for each labeled bar in the bar chart
        let sortedColors = []
        Object.keys(sorted).forEach(key => {
            sortedColors.push(colorValues[key])
        })

        flowRef.current.data.labels = Object.keys(sorted)
        flowRef.current.data.datasets[0].data = Object.values(sorted)
        flowRef.current.data.datasets[0].backgroundColor = sortedColors
        flowRef.current.data.datasets[0].borderColor = sortedColors

        flowRef.current.update()

        // chart 2 - stacked chats

        const timeSample = 10

        const timestampDatas = {}
        objects.forEach(object => {
            const timestamp = Math.floor(object.timestamp / timeSample)
            const timestampLabel = `${timestamp * timeSample} - ${timestamp * timeSample + timeSample}s`

            if (timestampDatas[timestampLabel]) {
                timestampDatas[timestampLabel].push(object)
            } else {
                timestampDatas[timestampLabel] = []
                timestampDatas[timestampLabel].push(object)
            }
        })

        const timestampObjects = {}
        Object.keys(timestampDatas).forEach(key => {
            timestampObjects[key] = []
            timestampDatas[key].forEach(object => {
                if (timestampObjects[key][object.class_name]) {
                    timestampObjects[key][object.class_name] += 1
                } else {
                    timestampObjects[key][object.class_name] = 1
                }
            })
        })
        let classes = []
        // each labels
        Object.keys(timestampObjects).forEach((key, i) => {
            Object.keys(timestampObjects[key]).forEach((class_name, j) => {
                if (!classes.includes(class_name)) {
                    classes.push(class_name)
                }
            })
        })
        let datasets = []
        classes.forEach((class_name, i) => {
            let data = []
            Object.keys(timestampObjects).forEach((key, j) => {
                if (timestampObjects[key][class_name]) {
                    data.push(timestampObjects[key][class_name])
                } else {
                    data.push(0)
                }
            })
            data.map((value, i) => {
                if (value > 1) {
                    data[i] = Math.round(value / 17)
                }
            })
            datasets.push({
                label: class_name,
                data: data,
                fill: false,
                borderColor: colorValues[class_name],
                backgroundColor: colorValues[class_name],
                borderWidth: 1
            })
        })


        timestampRef.current.data.labels = Object.keys(timestampDatas)
        timestampRef.current.data.datasets = datasets
        timestampRef.current.update()
    }, [props.objects])

    return <>
        <div className='c-carac-graphics'>
            <h2 className="c-carac-graphics__title || u-subtitle">Statistiques</h2>
            <div ref={containerRef} className='c-carac-graphics__content'>
                <div className='c-carac-graphics__flow'>
                    <canvas className='c-carac-graphics__canvas' ref={flowChartRef}></canvas>
                </div>
                <div className='c-carac-graphics__timestamp'>
                    <canvas className='c-carac-graphics__canvas' ref={timestampChartRef}></canvas>
                </div>
            </div>
        </div>
    </>
}