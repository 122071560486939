import { useState, useEffect } from 'react';
import { ArrowIcon, HelpIcon } from './Icons';


export default function CaracClassification(props) {

    const [counter, setCounter] = useState(0)
    const [datas, setDatas] = useState([])
    const [images, setImages] = useState([])
    const [slider, setSlider] = useState(0)

    const getRandom = (min, max) => {
        return (Math.round((Math.random() * (max - min + 1)) + min) * 100) / 100;
    }

    const getClasses = (i) => {
        if (i == 1 || i == 2) return [
            {
                class: 'PET-MONOTRAY',
                percentage: getRandom(40, 54)
            },
            {
                class: 'PET-BOTTLE_CLEAR',
                percentage: getRandom(15, 25)
            },
        ]
        // else if (i == 2) return [
        //     {
        //         class: 'PET-MONOTRAY',
        //         percentage: getRandom(40, 55)
        //     },
        //     {
        //         class: 'PET-BOTTLE_CLEAR',
        //         percentage: getRandom(15, 25)
        //     },
        // ]
        else if (i == 3) return [
            {
                class: 'PS',
                percentage: getRandom(40, 55)
            },
            {
                class: 'PET-MONOTRAY',
                percentage: getRandom(15, 25)
            },
        ]
        else if (i == 4) return [
            {
                class: 'JRM',
                percentage: getRandom(40, 55)
            },
            {
                class: 'FILM',
                percentage: getRandom(15, 25)
            },
        ]
        else if (i == 5) return [
            {
                class: 'FILM',
                percentage: getRandom(40, 55)
            },
            {
                class: 'PAPIER',
                percentage: getRandom(15, 25)
            },
        ]
    } 

    useEffect(() => {
        if (props.carac.id == 1 ) return

        const nbImages = 5

        const { time, duration} = props.temp
        const t = duration / nbImages
        const i = Math.floor(time / t)
        if (i != counter) {
            setCounter(i)
        }
    }, [props.temp])

    useEffect(() => {
        const image = `/assets/images/cropped/image0${counter}.jpg`
        if (typeof (counter) == 'number' && !images.includes(image) && counter >= 1) {
            setImages(images => [...images, image])
            const object = {
                image: image,
                timestamp: (new Date).toLocaleString(),
                potential_classes: [
                    getClasses(counter)[0],
                    getClasses(counter)[1]
                ]
            }
            setDatas(datas => [...datas, object])

            // PAS PLUS DE 55 - PAS MOINS DE 15 - 25
            // 1 / Multitray - Coulored
            // 2 / Monotray - PET-BOTTLE_CLEAR
            // 3 / PS - PET-MONOTRAY
            // 4 / JRM - FILM
            // 5 / FILM - PAPIER
            



        }
    }, [counter])


    const handleValidate = (e) => {
        e.preventDefault()
        setDatas(datas => datas.filter((item, index) => index != slider))
        setSlider(0)
    }

    return <>
        <div className='c-carac-classification'>
            <h2 className='c-carac-classification__title || u-subtitle'>Classification des inconnus</h2>
            <div className='c-carac-classification__slider'>
                <div className='c-carac-classification__slider-container'>
                    <div className='c-carac-classification__slider-images'>
                        { datas.length > 0 && datas.map((item, index) => {
                            return <div key={index} className={`c-carac-classification__slider-item ${slider == index ? '--active' : ''}`}>
                                <img src={item.image} alt={`Image ${index}`} className='c-carac-classification__slider-image' />
                            </div>
                        })                      
                        }
                    </div>
                </div>
                { datas.length == 0 && <>
                    <div className='c-carac-classification__slider-poster'>
                        <span className="c-carac-classification__slider-poster__info">
                            <div className="c-carac-classification__slider-poster__icon">
                                <HelpIcon color="white" rotate="0"/>
                            </div>
                            Pas d'objet inconnu.
                        </span>
                    </div>
                </>
                }
                <div className='c-carac-classification__slider-options'>
                    <button className='c-carac-classification__slider-button' onClick={() => setSlider(slider - 1)} disabled={slider == 0} style={{ opacity : (slider==0 || datas.length < 1) ? "0" : "1" }}><ArrowIcon rotate={'90'} /></button>
                    <button className='c-carac-classification__slider-button' onClick={() => setSlider(slider + 1)} disabled={slider == datas.length - 1}  style={{ opacity : (slider==datas.length - 1 || datas.length < 1) ? "0" : "1" }}><ArrowIcon rotate={'270'}/></button>
                </div>
            </div>
            <ul className='c-carac-classification__infos'>
                { datas.length > 0 && <>
                    <li className='c-carac-classification__info'>
                        <span className='c-carac-classification__info__label'>Objet inconnu :</span>
                        <span className='c-carac-classification__info__value'>{ slider + 1 } / {datas.length}</span>
                    </li>
                    <li className='c-carac-classification__info'>
                        <span className='c-carac-classification__info__label'>Date d'observation</span>
                        <span className='c-carac-classification__info__value'>{ datas[slider].timestamp }</span>
                    </li>
                    <li className='c-carac-classification__info'>
                        <span className='c-carac-classification__info__label'>Classifications probables :</span>
                        <ul className='classification__info__array-value'>
                            { datas[slider].potential_classes.map((item, index) => {
                                return <li key={index} className='c-carac-classification__info__value'>{item.class} : {item.percentage}%</li>
                            })}
                        </ul>
                    </li>
                </>
                ||
                <>
                <li className='c-carac-classification__info'>
                        <span className='c-carac-classification__info__label'>Pas d'objet inconnu détecté</span>
                    </li>
                </>
                } 
                
            </ul>
            <form className="c-carac-classification__form">
                <div className='c-carac-classification__item'>
                    <label className="c-carac-classification__label">Nature de l'objet :</label>
                    <select className="c-carac-classification__item__select || u-form__input" name="selectClass" id="selectClass">
                        <option value="PET">Hors classe</option>
                        <option value="PET">Emballage alimentaire</option>
                        <option value="PET">Emballage non-alimentaire</option>
                        <option value="PET">D3E</option>
                        <option value="PET">Inerte</option>
                        <option value="PET">Habit / Textile</option>
                        <option value="PET">Pile / Accumulateur</option>
                        <option value="PET">Organique / Déchet vert</option>

                    </select>
                </div>
                <div className='c-carac-classification__item'>
                    <label className="c-carac-classification__label">Matière de l'objet :</label>
                    <select className="c-carac-classification__item__select || u-form__input" name="selectClass" id="selectClass">
                        <option value="PET">Hors classe</option>
                        <option value="PET">PET</option>
                        <option value="PET">PEHD</option>
                        <option value="PET">PELD</option>
                        <option value="PET">PP</option>
                        <option value="PET">PS</option>
                        <option value="PET">PVC</option>
                        <option value="PET">PSE</option>
                        <option value="PET">Carton</option>
                        <option value="PET">Acier</option>
                        <option value="PET">Alluminum</option>
                        <option value="PET">Papier</option>
                    </select>
                </div>
                <div className='c-carac-classification__item'>
                    <label className="c-carac-classification__label">Couleur :</label>
                    <select className="c-carac-classification__item__select || u-form__input" name="selectClass" id="selectClass">
                        <option value="PET">Hors classe</option>
                        <option value="PET">Transparent rouge</option>
                        <option value="PET">Transparent vert</option>
                        <option value="PET">Transparent bleu</option>
                        <option value="PET">Transparent clair</option>
                        <option value="PET">Transparent marron</option>
                        <option value="PET">Transparent coloré</option>
                        <option value="PET">Opaque rouge</option>
                        <option value="PET">Opaque vert</option>
                        <option value="PET">Opaque bleu</option>
                        <option value="PET">Opaque marron</option>
                        <option value="PET">Opaque blanc</option>
                        <option value="PET">Opaque gris</option>
                        <option value="PET">Opaque noir</option>
                    </select>
                </div>
                <div className='c-carac-classification__item'>
                    <label className="c-carac-classification__label">Type d'objet :</label>
                    <select className="c-carac-classification__item__select || u-form__input" name="selectClass" id="selectClass">
                        <option value="PET">Hors classe</option>
                        <option value="PET">Mouchoir</option>
                        <option value="PET">Pot de fleurs</option>
                        <option value="PET">Barquette</option>
                        <option value="PET">Pot de yogourt</option>
                        <option value="PET">Couvercle</option>
                        <option value="PET">Film</option>
                        <option value="PET">Boîte de conserves</option>
                        <option value="PET">Cannette</option>
                        <option value="PET">Aerosol</option>
                        <option value="PET">Boîte à oeuf</option>
                        <option value="PET">Masque</option>
                        <option value="PET">Brique tétrapack</option>
                        <option value="PET">Tube dentifrice</option>
                        <option value="PET">Feuille volante</option>
                        <option value="PET">Cartonette</option>
                        <option value="PET">Papier kraft</option>
                        <option value="PET">Journal</option>
                        <option value="PET">Magazine</option>
                        <option value="PET">Annuaire</option>
                    </select>
                </div>
                <button className='c-carac-classification__form__button c-button || --success' onClick={handleValidate}>Valider</button>
            </form>
        </div>
    </>;
    }