import Logo          from './Logo';
import { BoardIcon } from './Icons';
import IconLink      from './IconLink';
import Menu     from './Menu';

export default function Header() {

    return <>
      <header className="c-header">
        <div className="c-header__top">
          <div className="c-header__wrapper-top">
            <div className="c-header__logo">
                <Logo color='white' />
            </div>
            <div className="c-header__home">
                <IconLink link="/" text="Dashboard">
                    <BoardIcon color='white' rotate=''/>
                </IconLink>
            </div>
            <div className="c-header__menu">
                <Menu/>
            </div>
            <div className="c-header__user">
            </div>
            <div className="c-header__alerts">
            </div>
            <div className="c-header__parameters">
            </div>
          </div>
          </div>
      </header>
      <div 
        className='l-top-offset' 
        style={{height: '100px'}}
      ></div>
    </>
}
