import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ArrowIcon, ListIcon } from "./Icons";

export default function Menu() {

    const menuRef = useRef(null);

    const [displayMenu, setDisplayMenu] = useState(false);
    const navLinks = [
        { url: '/', title: 'Wasoria Carac' },
        // { url: '/admin', title: 'Admin board' },
    ];
    
    const handleDisplay = () => {
        setDisplayMenu(!displayMenu);
    }
    const scrollTo0 = () => {
        window.scrollTo(0, 0);
        setDisplayMenu(false);
    }
    useEffect(() => {
        const ClickHandle = (e) => {
            if (menuRef.current && !(menuRef.current).contains(e.target)) {
                setDisplayMenu(false)
            }
        }
        window.addEventListener('click', ClickHandle)
        return () => {
            window.removeEventListener('click', ClickHandle)
        }
    }, [])
    return <>
    <div className="c-menu" ref={menuRef}>
        <div className="c-menu__button" onClick={handleDisplay}>
            <ListIcon color={'white'} rotate="" />
            <span>Services</span>
            <ArrowIcon color={'white'} rotate=""/>
        </div>
        <nav className={"c-menu__nav || " + (displayMenu ? ' --is-show ' : '')}>
          <ul className="c-menu__list">
            {navLinks.map((link, index) => (
              <li key={index} className="c-menu__item">
                <Link onClick={scrollTo0} className="c-menu__link" to={link.url}>{link.title}</Link>
              </li>
            ))}
          </ul>
        </nav>
    </div>
    </>
}