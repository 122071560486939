import  { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

export default function CaracArray(props) {

    const { objects, carac } = props
    const expertContainerRef = useRef(null)
    const flowDevChartRef = useRef(null)
    const flowDevRef = useRef(null)

    const colorValues = {
        "PET-BOTTLE_CLEAR" : 'rgba(255, 206, 86, 1.0)',
        "PET-BOTTLE_COLOURED" : 'rgba(209, 199, 47, 1.0)',
        "PET-MONOTRAY" : 'rgba(60, 218, 1, 1.0)',
        "PET-MULTITRAY" : 'rgba(143, 188, 11, 1.0)',
        "PP" : 'rgba(44, 197, 136, 1.0)',
        "PS" : 'rgba(22, 127, 48, 1.0)',
        "PSE" : 'rgba(19, 181, 183, 1.0)',
        "HDPE" : 'rgba(255, 165, 154, 1.0)',
        "FILM" : 'rgba(255, 73, 54, 1.0)',
        "OTHER" : 'rgba(75, 192, 192, 1.0)',
    }

    const fluxDevClasses = [
        "PET-BOTTLE_COLOURED",
        "PET-MONOTRAY",
        "PET-MULTITRAY",
        "PS",
        "PSE",
    ]

    const petClairClasses = [
        "PET-BOTTLE_CLEAR",
    ]

    useEffect(() => {

        const containerHeight = expertContainerRef.current.clientHeight
        const containerWidth = expertContainerRef.current.clientWidth
        flowDevChartRef.current.height = containerHeight - 25
        flowDevChartRef.current.width = containerWidth


        const ctx1 = flowDevChartRef.current;
        const chartDev = new Chart(ctx1, {
            type: 'bar',
            data: {
                labels: [], // Labels for x-axis
                datasets: [{
                    label: 'Répartition par classe',
                    data: [], // Initial data for y-axis
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: function(value) {
                                return (value) + ' %';
                            }
                        }
                    },
                    x: {
                    }
                },
                animation: {
                    duration: 0
                },
            plugins: {
                title: {
                    display: true,
                    text: 'Répartition des classes'
                }
            }
        }});

        
        flowDevRef.current = chartDev
        return () => {
            chartDev.destroy()
        }

    }, [])

    useEffect(() => {
        const OBJS = [...objects]

        // chart 1 - flow chart
        const flowDatas = {}
        OBJS.forEach(object => {
            if (props.carac.id == 2 && fluxDevClasses.includes(object.class_name)) {
                if (flowDatas["FLUX DEV"]) {
                    flowDatas["FLUX DEV"] += 1
                } else {
                    flowDatas["FLUX DEV"] = 1
                }
            }
            else if (flowDatas[object.class_name]) {
                flowDatas[object.class_name] += 1
            } else {
                flowDatas[object.class_name] = 1
            }
        })        

        const flowObjects = flowDatas
        Object.keys(flowObjects).forEach(key => {
            // round to 2 decimals
            flowObjects[key] = Math.round(flowObjects[key] / objects.length * 10000) / 100
        })

        // sort by value
        const sortable = Object.entries(flowObjects).sort(([,a],[,b]) => b-a)
        const sorted = Object.fromEntries(sortable)

        // select color for each labeled bar in the bar chart
        let sortedColors = []
        Object.keys(sorted).forEach(key => {
            sortedColors.push(colorValues[key])
        })

        flowDevRef.current.data.labels = Object.keys(sorted)
        flowDevRef.current.data.datasets[0].data = Object.values(sorted)
        flowDevRef.current.data.datasets[0].backgroundColor = sortedColors
        flowDevRef.current.data.datasets[0].borderColor = sortedColors

        flowDevRef.current.update()
    }, [objects])


    return <>
        <div className="c-carac-array">
            <h2 className="c-carac-array__title || u-subtitle">Caractérisation : {props.carac.flow_type }</h2>

            <div ref={expertContainerRef} className="c-carac-array__content">
                <div className='c-carac-array__flow'>
                    <canvas className='c-carac-array__canvas' ref={flowDevChartRef}></canvas>
                </div>
            </div>
        </div>
    </>
}