import { useState, useEffect, useRef } from "react";
import { HelpIcon } from "./Icons";
import VideoPlayer from "./VideoPlayer";

export default function CaracStreaming(props) {

    const { carac, box } = props

    const videoRef = useRef(null)
    const videoWrapperRef = useRef(null)

    const [status, setStatus] = useState('waiting')
    
    const handleTime = (time) => {
        props.sendTime(time)
    }

    const handleStart = () => {
      const height = videoWrapperRef.current.clientHeight
      const width = videoWrapperRef.current.clientWidth
      videoRef.current.height = height
      videoRef.current.style.height = height + 'px'
      videoRef.current.src = box.video
      
      setTimeout(() => {
        videoRef.current.style = 'display: block'
        setStatus('running')
      }, 50)
    }


    const handleEnd = () => {
        setStatus('done')
    }

    const handlePause = () => {
      if (status == 'running')
        setStatus('paused')
      else
        setStatus('running')
    }

    useEffect(() => {
      props.sendStatus(status)
      if (status == 'running') {
        videoRef.current.play()
      }

      if (status == 'paused') {
        videoRef.current.pause()
      }

      if (status == 'done') {
        videoRef.current.pause()
      }

    }, [status])

    return <>
      <div className='c-carac-streaming'>
        <h2 className='c-carac-streaming__title || u-subtitle'>Flux observé</h2>
          <div ref={videoWrapperRef} className='c-carac-streaming__video-background'>
            <div className="c-carac-streaming__poster">
              <span className="c-carac-streaming__poster__info">
                <div className="c-carac-streaming__poster__icon">
                  <HelpIcon color="white" rotate="0"/>
                </div>
                Pas d'entrée vidéo.
                </span>
            </div>
            <VideoPlayer sendTime={handleTime} style={{display : 'none'}} ref={videoRef} wrapper={videoWrapperRef.current}/>
          </div>
          <ul className='c-carac-streaming__infos'>
            <li className='c-carac-streaming__info'>
              <span className='c-carac-streaming__info__label'>Début de la caractérisation :</span>
              <span className='c-carac-streaming__info__value'>{ carac.started_at && carac?.started_at.toLocaleString() }</span>
            </li>
            <li className='c-carac-streaming__info'>
              <span className='c-carac-streaming__info__label'>Fin de la caractérisation :</span>
              <span className='c-carac-streaming__info__value'>{ carac.status == "done" && (new Date).toLocaleString}</span>
            </li>
            <li className='c-carac-streaming__info'>
              <span className='c-carac-streaming__info__label'>Saisie de l'échantillon :</span>
              <span className='c-carac-streaming__info__value'>{ carac.sample }</span>
            </li>
            <li className='c-carac-streaming__info'>
              <span className='c-carac-streaming__info__label'>Flux :</span>
              <span className='c-carac-streaming__info__value'>{ carac.flow_type }</span>
            </li>
            <li className='c-carac-streaming__info'>
              <span className='c-carac-streaming__info__label'>Box :</span>
              <span className='c-carac-streaming__info__value'>{box.name}</span>
            </li>
            <li className='c-carac-streaming__info'>
              <span className='c-carac-streaming__info__label'>Etat de la caractérisation :</span>
              <span className='c-carac-streaming__info__value'>
                {
                  carac.status == 'running' ? 'En cours' : (carac.status == 'done' ? 'Terminée' : (carac.status == 'waiting' ? 'En attente' :  (carac.status == 'paused' ? 'En pause' : (carac.status == 'error' ? 'Erreur' : 'Inconnu'))))
                }
              </span>
            </li>
          </ul>
          <ul className='c-carac-streaming__actions'>
            {
              status == 'waiting' && <>
              <li className='c-carac-streaming__action'>
                <button className='c-button || --success' onClick={handleStart}>Démarrer</button>
              </li>
              <li className='c-carac-streaming__action'>
                <button className='c-button || --warning'>Modifier</button>
              </li>
              </>
            }
            {
              status != 'waiting' && <>
              <li className='c-carac-streaming__action'>
                <button className='c-button || --success' onClick={handleEnd}>Fin de carac'</button>
              </li>
              <li className='c-carac-streaming__action'>
                <button className='c-button || --warning' onClick={handlePause}>{ status == 'running' ? 'Pause' : 'Reprendre'}</button>
              </li>
              <li className='c-carac-streaming__action || --left'>
                <button className='c-button || --danger'>Supprimer</button>
              </li>
              </>
            }
          </ul>
        </div>
    </>
}