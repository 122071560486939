function Logo({color}) {
    return <>
        <svg  className={`c-logo || --is-${color ? color : 'white'} `} id="Calque_2" data-name="Calque 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 674.64 626.5">
        <defs>
            <linearGradient id="gradient-1" data-name="gradient-1" x1="0" y1="362.27" x2="674.64" y2="362.27" gradientUnits="userSpaceOnUse">
              <stop offset=".01" stopColor="#683c90"/>
              <stop offset=".07" stopColor="#683c90"/>
              <stop offset=".14" stopColor="#5a3f92"/>
              <stop offset=".22" stopColor="#564092"/>
              <stop offset=".32" stopColor="#494c9b"/>
              <stop offset=".38" stopColor="#474f9d"/>
              <stop offset=".46" stopColor="#4454a1"/>
              <stop offset=".51" stopColor="#3e5fa9"/>
              <stop offset=".6" stopColor="#3566ac"/>
              <stop offset=".67" stopColor="#2c5d9a"/>
              <stop offset=".71" stopColor="#26578f"/>
              <stop offset=".77" stopColor="#1c4d7b"/>
              <stop offset=".87" stopColor="#013c58"/>
            </linearGradient>
            <linearGradient id="gradient-2" data-name="gradient-2" x1="190.73" y1="42.68" x2="310.39" y2="42.68" gradientUnits="userSpaceOnUse">
              <stop offset="0" stopColor="#683c90"/>
              <stop offset=".08" stopColor="#5a3f92"/>
              <stop offset=".18" stopColor="#564092"/>
              <stop offset=".34" stopColor="#494c9b"/>
              <stop offset=".5" stopColor="#4454a1"/>
              <stop offset=".74" stopColor="#3769b1"/>
              <stop offset=".8" stopColor="#3467ad"/>
              <stop offset=".85" stopColor="#2d61a1"/>
              <stop offset=".91" stopColor="#22578e"/>
              <stop offset=".96" stopColor="#124a74"/>
              <stop offset="1" stopColor="#013c58"/>
            </linearGradient>
          </defs>
          
          <g id="Calque_2-2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1-2">
              <path className="cls-1" d="m317.2,98.05l69.55,129.63c5.39,10.04,19.79,10.08,25.23.07l65.34-120.05c2.51-4.64,7.38-7.52,12.66-7.49l170.37.85c10.76.05,17.63,11.49,12.61,21.03l-261.73,496.75c-5.35,10.16-19.87,10.23-25.31.12L158.21,196.04c-.16-.3-.32-.58-.51-.85h0c1.98-3.45,3.32-7.33,3.89-11.49,2.35-17.4-9.84-33.41-27.25-35.76-17.4-2.34-33.41,9.85-35.75,27.26-2.26,16.66,8.82,32.04,25.05,35.37.19.5.42.99.67,1.48l152.06,289c5,9.5-1.83,20.93-12.56,21.03l-40.53.35c-5.35.04-10.28-2.89-12.79-7.62L1.69,121.48c-5.04-9.5,1.77-20.96,12.53-21.07l172.85-1.73c5.39-.05,10.37,2.93,12.87,7.72l186.64,357.92c5.36,10.26,20.04,10.28,25.42.03l110.52-210.53c.63-1.2,1.08-2.45,1.33-3.7,13.48-3.15,23.51-15.25,23.51-29.68,0-16.83-13.65-30.48-30.49-30.48s-30.49,13.65-30.49,30.48c0,5.88,1.65,11.35,4.54,16-.24.36-.47.73-.69,1.12l-77.99,140.93c-5.49,9.89-19.74,9.85-25.15-.08L234.32,98.26l82.88-.21Z"/>
              <path className="cls-2" d="m227.28,85.36l-34.78-63.77c-5.19-9.52,1.65-21.12,12.49-21.2L255.89,0c5.32-.04,10.23,2.86,12.76,7.56l41.75,77.8h-83.12Z"/>
            </g>
          </g>
        </svg>
    </>
}

export default Logo;