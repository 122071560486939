import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import "./App.scss";
import CaracPage from "./pages/CaracPage";

export default function App() {
    return (
      <BrowserRouter>
        <Layout />
        <Routes>
            <Route index element={<Home />} />
            <Route path="carac/:videoId" element={<CaracPage />} />
        </Routes>
      </BrowserRouter>
    );
  }