import { useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderSection from "../components/HeaderSection";
import CaracDashboard from "../components/CaracDashboard";

export default function Home() {

    let { videoId } = useParams();

    const [ isExpert, setIsExpert ] = useState(true)

    const handleExpert = () => {
        setIsExpert(!isExpert)
    }

    return <>
        <main className="l-main">
            <HeaderSection title={'Wasoria CARAC'} expert={() => {handleExpert()}}/>
            <CaracDashboard videoId={videoId} expert={isExpert}/>
        </main>
    </>
}