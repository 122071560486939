import { useState } from "react";

export default function HeaderSection({children, title, button, expert}) {

    const [isExpert, setIsExpert] = useState(false)

    const handleExpert = () => {
        if (expert) {
            expert()
            setIsExpert(!isExpert)
        }
    }

    return <>
        <div className="c-header-section">
            { children }
            <div className='c-header-section__header'>
                <h1 className="c-header-section__title u-main-title">{ title }</h1>
                {
                    expert &&
                    <div className="c-header-section__expert">
                        <button className="c-header-section__button || c-button" onClick={handleExpert}>{
                            !isExpert ? 'Mode expert' : 'Mode utilisateur'
                        }</button>
                    </div>
                }
                {
                    button &&
                    <div className="c-header-section__expert">
                    {/* <Button text='Ajouter' onClick={button}/> */}
                    </div>
                }
            </div>
        </div>
    </>
}