import React, { useState, useRef, useEffect, forwardRef } from 'react';

const VideoPlayer = forwardRef(({ sendTime, wrapper }, ref) => {
  const [currentTime, setCurrentTime] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const updateTime = () => {
        sendTime({
            time: video.currentTime,
            duration: video.duration
        });
        setCurrentTime(video.currentTime);
    };

    // Add event listener to update timecode when time updates
    video.addEventListener('timeupdate', updateTime);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      video.removeEventListener('timeupdate', updateTime);
    };
  }, []);

  // Forwarding the ref
  useEffect(() => {
    if (ref) {
      ref.current = videoRef.current;
    }
  }, [ref]);

  return (
    <>
      <div className='c-carac-streaming__video-container'>
        <video style={{ display: 'none'}} controls className="c-carac-streaming__video" ref={videoRef} id='video'>
          <source src="your-video-url.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
});

export default VideoPlayer;
