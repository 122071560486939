import { Link } from 'react-router-dom';

export default function IconLink({text, link, children}) {
    const scrollTo0 = () => {
        window.scrollTo(0, 0);
    }
    return <>
        <div onClick={scrollTo0} className='c-icon-link'>
            {children}
            <Link to={link}>{text}</Link>
        </div>
    </>
}