import CaracStreaming from './CaracStreaming';
import CaracClassification from './CaracClassification';
import CaracGraphics from './CaracGraphics';
import CaracArray from './CaracArray';

import jsonData1 from '../datas/datas_1.json';
import jsonData2 from '../datas/datas_2.json';

import { useCallback, useEffect, useRef, useState } from "react";

export default function CaracDashboard(props) {

    const [carac, setCarac] = useState({});
    const [box, setBox] = useState({});
    const [objects , setObjects] = useState([]);
    const [time, setTime] = useState(0);


    useEffect(() => {
        const now = new Date
        if (props.videoId == '1') {
            setBox({
                name : "Wasoria Box 1",
                display_name : "WB1",
                flow_type : "Plastiques",
                video : "/assets/videos/video1.mp4"
            })
            setCarac({
                id: 1,
                name: "Caractérisation 1",
                started_at : now,
                updated_at : null,
                status : 'waiting',
                flow_type : "PETClair",
                conveyor_speed : 1,
                sample: 'Portique table PETClair',
            })
        }
        if (props.videoId == '2') {
            setBox({
                name : "Wasoria Box 2",
                display_name : "WB2",
                flow_type : "Papier",
                video : "/assets/videos/video2.mp4"
            })
            setCarac({
                id: 2,
                name: "Caractérisation 2",
                started_at : now,
                updated_at : null,
                status : 'waiting',
                flow_type : "Flux développement",
                conveyor_speed : 1,
                sample: 'Portique table Flux développement',
            })
        }
    }, [])


    const handleStatus = (status, time) => {
        console.log('status', status)
        setCarac({...carac, status: status})
    }

    const handleTime = (temp) => {
        const { time, duration } = temp
        const fps = 17
        let frame = (time * fps)

        let processedObjects = []
        let classes = {}
        if (props.videoId == '1') {
            classes = jsonData1.categories
            processedObjects = jsonData1.annotations.filter((obj) => {
                return obj.image_id <= frame
            })
        } else {
            classes = jsonData1.categories
            processedObjects = jsonData2.annotations.filter((obj) => {
                return obj.image_id <= frame
            })
        }

        let classedObjects = []
        classedObjects = processedObjects.map((obj) => {
            return {
                id : obj.id,
                timestamp : obj.image_id / fps,
                class_name : classes.find((c) => c.id == obj.category_id).name,
            }
        })

        setObjects(classedObjects)
        setTime(temp)
    }



    return <>
        <section className='l-carac-dashboard'>
            <div className='l-carac-dashboard__left'>
                <CaracStreaming  sendStatus={handleStatus} sendTime={handleTime} carac={carac} box={box} />
            </div>
            <div className='l-carac-dashboard__right'>
            {
                props.expert && <>
                    <CaracClassification carac={carac} box={box} temp={time}/>
                    <CaracGraphics carac={carac} box={box} objects={[...objects]} /> 
                </>
            }
            {
                !props.expert && <>
                    <CaracArray carac={carac} box={box} objects={[...objects]} />
                </>
            }
            </div>
        </section>
    </>
}
